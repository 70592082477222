// @ts-strict-ignore
import { isEmpty, isNil, omitBy } from 'lodash'
import qs from 'qs'
import urljoin from 'url-join'
import { LessonReviewType } from '../components/portal/webview/lessons/LessonReviewBody'
import { getEnv } from './envUtil'

export const SERVER_URL = getEnv('CONF_SERVER_URL')
// export const SERVER_URL = 'https://api.ringleplus.com'
// SERVER_URL을 바꾸고 싶으면, 위에 주소를 직접 수장하지 말고 .env.local 파일에 CONF_SERVER_URL를 추가해 주세요
//example : https://qa2.ringleserver.com/api/v4/student/matching/schedule

const teensV1 = (path) => urljoin(`${SERVER_URL}/api/teens/v1`, path)
const v4Student = (path) => urljoin(`${SERVER_URL}/api/v4/student`, path)
const v4 = (path) => urljoin(`${SERVER_URL}/api/v4`, path)
export const urlParams = (obj, nullable?) => {
  if (nullable) {
    return isEmpty(obj) ? '' : '?' + qs.stringify(obj)
  }

  const nilRemoved = omitBy(obj, isNil)
  if (isEmpty(nilRemoved)) {
    return ''
  }
  return '?' + qs.stringify(nilRemoved)
}
const apis = {
  captcha: '/api/v1/captcha',
  slackNotification: '/api/v1/slackNotification',
  contentPrograms: {
    enroll: () => v4Student('/content_programs/enroll'),
    enrolled: () => v4Student('/content_programs/enrolled'),
    purchase: () => v4Student('/content_program_purchases'),
    getPurchase: (purchaseId) => v4Student(`/content_program_purchases/${purchaseId}`),
    purchaseCheckout: (purchaseId) => v4Student(`/content_program_purchases/${purchaseId}/checkout`),
  },
  account: () => teensV1('/accounts'),
  referrals: (code: string) => teensV1(`/referrals/code/${code}`),
  geolocation: () => teensV1('/geolocation'), // 📍 v4Student - 데이터독 호출 O
  geolocation_by_ip: (ip) => teensV1(`/geolocation_by_ip?ip=${ip}`), // 📍 v4Student - 데이터독 호출 X, 코드 or 정책확인 필요
  track: () => teensV1(`/log`),
  track_visit: () => teensV1(`/log/visit`),
  track_click: () => teensV1(`/log/click`),
  agreeTransferPolicy: () => teensV1(`/users/transfer_policy`),
  error: () => v4(`/page_view/error`),
  lesson_guide: {
    get: () => v4('/users/lesson_guide'),
  },
  // contents_newletter 확인 후 제거 필요
  contents_newsletter: {
    unsubscribe_reason: () => v4Student(`/contents_newsletter/unsubscribe_reason`), // 📍 v4Student - 데이터독 호출 X, 코드 or 정책확인 필요
  },
  materials: (value) => teensV1(`/materials/?filter=${value}`),
  auth: {
    me: () => v4('/users/me'),
    notificationSettings: () => teensV1(`/accounts/notification_settings`),
    notificationUpdate: (id, childId?) =>
      teensV1(
        childId
          ? `/accounts/notification_setting_option/${id}?child_id=${childId}`
          : `/accounts/notification_setting_option/${id}`
      ),
    get: () => teensV1('/accounts'),
    accountTypes: () => teensV1('accounts/types'),
    token: () => teensV1('/oauth/token'),
    signin: {
      socialAccount: () => teensV1('/accounts/signin/sns'), // will be deleted
      email: () => teensV1('/accounts/signin/email'), // will be deleted
    },
    onboardingStage: () => teensV1('/accounts/child_onboarding_stage'),
    children: () => teensV1('/accounts/children'),
    teensSignup: {
      parent: {
        email: () => teensV1('/accounts/signup/parent/email'),
        emailValidate: () => teensV1('/accounts/signup/parent/validate/email'),
        socialAccount: () => teensV1('/accounts/signup/parent/social_account'),
        phoneValidate: () => teensV1('/accounts/signup/parent/validate/phone'),
        validateGiftCode: (giftCode) => teensV1(`/accounts/signup/parent/validate/gift_code?gift_code=${giftCode}`),
      },
      child: () => teensV1('/accounts/signup/child'),
    },
    passwordReset: () => teensV1(`/accounts/reset_password`),
    password: {
      reset: () => teensV1(`/accounts/password`),
      resetToken: {
        validate: () => teensV1(`/accounts/password/reset_token/validate`),
        create: () => teensV1(`/accounts/password/reset_token`),
      },
    },
    accountSwitch: (id, loggedByParent) => teensV1(`/accounts/switch?id=${id}&logged_by_parent=${loggedByParent}`),
    passCode: (id, passcode, loggedByParent) =>
      teensV1(`/accounts/passcode?id=${id}&passcode=${passcode}&logged_by_parent=${loggedByParent}`),
    survey: () => teensV1('/accounts/survey'),
    eventProps: {
      userInfo: () => teensV1('/users/event_properties'),
    },
  },
  todo: () => teensV1('/home/todo_list'),
  curation: {
    curations: () => teensV1('/home'),
  },
  home: {
    main: () => teensV1('/home'),
  },
  personalization: () => teensV1('/home/personalization'),
  myOriginal: () => teensV1('/my_original'),
  likeMaterial: () => teensV1(`/materials/like`),
  likeWebinar: () => teensV1('/my_original/webinar'),
  referral: (code) => teensV1(`/referral?referral_code=${code}`),
  course: {
    detail: (id) => teensV1(`/materials/${id}`),
    filters: () => teensV1(`/materials/filters`),
    byFilter: (filter) => teensV1(`/materials/by_filter?${filter}`),
  },
  notice: {
    policy: (locale, type) => teensV1(`/notices/policy?locale=${locale}&type=${type}`), //v1 tutor api pending 상태
  },
  landing: {
    // TEENS LANDING
    tutors: () => teensV1('/landing/tutors'),
    materials: () => teensV1(`/landing/materials`),
  },
  tutor: {
    my: () => teensV1(`/tutor/my`),
    my_type: () => teensV1(`/tutor/my_type`),
    list: (page = 1, couponId?: number) =>
      teensV1(`/tutors${urlParams({ per_page: 20, page, coupon_type_id: couponId })}`),
    newTutors: (page = 1, couponId?) =>
      couponId
        ? teensV1(`/tutors?type=recommend&per_page=20&coupon_type_id=${couponId}&page=${page}`)
        : teensV1(`/tutors?type=recommend&per_page=20&page=${page}`),
    newUnoccupiedTutors: (page = 1) => teensV1(`/tutor/list?type=new_unoccupied&page=${page}`),
    oldUnoccupiedTutors: (page = 1) => teensV1(`/tutor/list?type=old_unoccupied&page=${page}`),
    allTutors: (page = 1, couponId?) =>
      couponId
        ? teensV1(`/tutors?type=all&per_page=20&coupon_type_id=${couponId}&page=${page}`)
        : teensV1(`/tutors?type=all&per_page=20&page=${page}`),
    myTutors: (page = 1, couponId?) =>
      couponId
        ? teensV1(`/tutors?type=my&per_page=20&coupon_type_id=${couponId}&page=${page}`)
        : teensV1(`/tutors?type=my&per_page=20&page=${page}`),
    experiencedTutors: () => teensV1(`/tutor/experienced`),
    search: (payload) => teensV1(`/tutors/search${urlParams(payload)}`),
    filter: () => teensV1(`/tutors/filter`),
    profile: (id, locale?, couponId?) =>
      teensV1(
        `/tutors/${id}${urlParams({
          locale: locale,
          coupon_type_id: couponId,
        })}`
      ),
    onlyTeensProfile: (id, locale?, couponId?) =>
      teensV1(
        `/tutors/${id}${urlParams({
          locale: locale,
          coupon_type_id: couponId,
        })}`
      ),
    add: () => teensV1(`/tutors/add`),
    delete: () => teensV1(`/tutors/delete`),
  },
  matching: {
    validateCoupon: () => teensV1('/matching/book_time_and_validate_coupon'),
    validateCouponForEdit: () => teensV1('/matching/book_time_and_validate_coupon_for_edit'),
    unbook: () => teensV1('/matching/unbook_time'),
    index: () => teensV1('/matching'),
    forEdit: ({ id }) => teensV1(`/matching/for_edit/${id}`),
    schedule: () => teensV1('/matching/schedule'),
    scheduleAtTime: () => teensV1('/matching/schedule_at_time'),
    materials: {
      index: () => teensV1(`/matching/materials`),
      category: (categoryId) =>
        teensV1(
          `/matching/materials${urlParams({
            course_large_hashtag_id: categoryId,
          })}`
        ),
      filter: (filter) => teensV1(`/matching/materials${urlParams({ filter: filter })}`),
      filterObj: (filter) => teensV1(`/matching/materials${urlParams(filter)}`),
      largeHashtagFilter: ({ largeHashtagId }) =>
        teensV1(`/matching/materials${urlParams({ course_large_hashtag_id: largeHashtagId })}`),
      search: ({ keyword, categoryId }: { keyword?: string; categoryId?: number }) =>
        teensV1(
          `/matching/materials/search${urlParams({
            keyword: keyword,
            course_large_hashtag_id: categoryId,
          })}`
        ),
    },
  },
  trace: () => teensV1(`/trace`),
  events: {
    userReport: ({ userId }) => teensV1(`/events/${userId}/user_report`),
    validateGiftCode: (giftCode) => teensV1(`/events/gift?gift_code=${giftCode}`),
    gift: () => teensV1(`/events/gift`),
  },
  lessons: {
    apply: () => teensV1('/lesson/apply'),
    applyAuto: () => teensV1('/lesson/apply_auto'),
    enter: (id) => teensV1(`/lesson/upcoming/${id}/enter`),
    setting: (id) => teensV1(`/lessons/${id}/setting`),
    upcomings: () => teensV1(`/lessons/upcoming`),
    upcomingsCount: () => teensV1(`/lesson/upcoming/count`),
    homeworks: () => teensV1(`/homeworks`),
    homeworkDetail: (id) => teensV1(`/homeworks/${id}`),
    lessonHomework: ({ lessonId }) => teensV1(`/lessons/${lessonId}/homework`),
    upcoming: (id) => teensV1(`/lessons/${id}`),
    editCourse: () => teensV1('/lesson/edit/course'),
    editTimeForSelectMatching: () => teensV1('/lesson/edit/time_for_select_matching'),
    classroom: (id) => teensV1(`/lesson/classroom/${id}`),
    pusherUrl: () => `${SERVER_URL}/pusher/auth_mobile`,
    chat: () => teensV1(`/rtc/talk_with_admin`),
    translate: (id) => teensV1(`lesson/classroom/${id}/translate`),
    exit: (id) => teensV1(`lesson/classroom/${id}/exit`),
    upload_link: (id) => teensV1(`/lessons/${id}/link_material`),
    upload_file: (id) => teensV1(`/lessons/${id}/file_material`),
    preparation: {
      get: ({ lessonId }) => teensV1(`/lesson/preparation/${lessonId}`),
      answer: (id) => teensV1(`/lesson/preparation/${id}/answer`),
      correction: () => teensV1(`/lesson/answer/corrections`),
      option: (id) => teensV1(`/lesson/preparation/${id}/option`),
      prestudy: (id) => teensV1(`/lesson/preparation/${id}/prestudy`),
    },
    unassigned: {
      coupon: (id) => teensV1(`/lessons/${id}/available_coupons`),
      get: () => teensV1(`/lessons/unassigned`),
      take: (id = null) => teensV1(`/lessons/${id}/take`),
    },
    //
    review: {
      past: () => teensV1(`/lessons/past/original_list`),
      suggestion: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/suggestions`),
      feedback: (lesson_id, item_type = 'all') => teensV1(`/lessons/past/${lesson_id}/feedback?item_type=${item_type}`),
      script: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/record/script`),
      analysis: (lesson_id) => teensV1(`/lesson/past/${lesson_id}/analysis`),
      lessonInfo: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/web`),
      postUseAnalysisUrl: () => teensV1(`/analysis/use`),
      phrase: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/phrase`),
      feedbackNew: (lesson_id) => teensV1(`/lessons/${lesson_id}/feedback`),
      lateFeedback: (lesson_id) => teensV1(`/lesson/feedback/${lesson_id}/late`),
      study: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/study`),
      late: (lesson_id) => teensV1(`/lessons/past/${lesson_id}/late`),
      index: ({ page = 1, size = 10, type }: { page?: number; size?: number; type: LessonReviewType }) =>
        teensV1(`/lessons/past/original_list${urlParams({ start: page, size, type })}`),
    },
    trial: {
      startTimes: () => teensV1(`/lessons/trial/start_times`),
      tutors: () => teensV1(`/lessons/trial/tutors`),
      courses: () => teensV1(`/lessons/trial/courses`),
      apply: () => teensV1(`/lessons/trial/apply`),
      lessonStyles: () => teensV1(`/lessons/trial/lesson_styles`),
    },
    evaluation: {
      eval: (lessonId) => teensV1(`/lessons/${lessonId}/eval`),
    },
  },
  credit: {
    my: () => teensV1('/credits/my'),
    summaryList: () => teensV1('/credits'),
    lessonHistory: (id) => teensV1(`/lesson_book?id=${id}`),
    lessonBook: () => teensV1(`/lesson_book`),
    receive: (id) => teensV1(`/lesson_book/credit?id=${id}`),
    // TEENS
    summary: () => teensV1('/credits/summary'),
  },
  point: {
    list: () => teensV1(`/points`),
    compensate: ({ pointId }: { pointId: number }) => teensV1(`/points/${pointId}/redeem`),
    referrals: () => teensV1('/referrals'),
  },
  products: {
    detail: (id) => teensV1(`/product/${id}`),
  },
  productLines: {
    list: () => teensV1('/product_lines'),
  },
  lesson: {
    get: (id) => teensV1(`/lesson/${id}`),
    cancel: (id) => teensV1(`lessons/${id}/cancel`),
    getShareAnswer: () => teensV1(`/lesson/answer/candidates`),
    answerSelect: () => teensV1(`/lesson/answer/select`),
    like: () => teensV1('/lesson/answer/like'),
    unlike: () => teensV1('/lesson/answer/unlike'),
  },
  profile: {
    get: () => teensV1(`/profile`),
    info: () => teensV1(`/profile/info`),
    onboard: () => teensV1('profile/onboard'),
    zoom: () => teensV1('/profile/zoom'),
    password: (id?: any) => teensV1(`/profile/password${urlParams({ child_id: id })}`),
    timezone: (id?: any) => teensV1(`/profile/timezone${urlParams({ child_id: id })}`),
    leave: () => teensV1('/profile/leave'),
    notifications: (id?: any) => teensV1(`/profile/notifications${urlParams({ child_id: id })}`),
    updateProfileImage: (id?: any) => teensV1(`/profile/avatar${urlParams({ child_id: id })}`),
    phoneCodeAuthorized: (locale, id?: any) => teensV1(`/profile/code${urlParams({ locale, child_id: id })}`),
    video: () => teensV1('/video/submit'),
    credential: () => teensV1('/profile/credential'),
  },
  curriculum: {
    get: () => teensV1('/curriculums'),
    detail: (id) => teensV1(`/curriculums/${id}`),
    register: () => teensV1('/curriculums'),
    recommend: () => teensV1('/curriculums/recommend'),
    my: () => teensV1('/curriculums/my'),
  },
  onboarding: {
    curriculum: () => teensV1('/onboarding/curriculum'),
  },
  verification: {
    phone: {
      request: () => teensV1(`/verification/phone/encrypted_request`),
      verify: () => teensV1(`/verification/phone/verify`),
      reset: () => teensV1(`/verification/phone/reset`),
    },
  },
  purchase: {
    create: () => teensV1(`/purchase/create_purchase`),
    get: ({ purchaseId }: { purchaseId: string }) => teensV1(`/purchase/${purchaseId}`),
    payment: () => teensV1('/purchase/payment'),
    check_payment_stripe: (id, secret) =>
      teensV1(`/purchase/check_payment_stripe?payment_intent_id=${id}&payment_intent_client_secret=${secret}`),
    check_payment_stripe_setup: (id, secret) =>
      teensV1(`/purchase/check_payment_stripe?setup_intent_id=${id}&setup_intent_client_secret=${secret}`),
    process: () => teensV1('/purchase/process'),
    vbank: () => teensV1('/purchase/vbank'),
  },
  purchases: {
    list: () => teensV1('/purchases'),
    create: () => teensV1('/purchases'),
    detail: ({ purchaseId }: { purchaseId: number }) => teensV1(`/purchases/${purchaseId}`),
    createStripeClientSecret: (id: number | string) => teensV1(`/purchases/${id}/stripe_payment_intent`),
  },
  evaluation: {
    post: (id) => teensV1(`lessons/${id}/evaluation`),
  },
  promotion: {
    gnb: () => v4('/gnb'),
    popup: () => v4('/popup'),
  },
  googleTranslate: () => v4(`/common/google_translate`),
  contentProgram: {
    enroll: () => teensV1('/content_programs/enroll'),
    enrollments: () => teensV1('/content_programs/enrollments'),
    enrolled: () => teensV1('/content_programs/enrolled'),
    credits: () => teensV1('/content_programs/credits'),
  },
}

export default apis
