import { getMonth, getYear } from 'date-fns'
import { isEmpty, isNaN, isNil, omitBy, overSome } from 'lodash'
import qs from 'qs'
import urljoin from 'url-join'
import { getEnv } from './envUtil'

export const SERVER_URL = getEnv('CONF_SERVER_URL')
// SERVER_URL을 바꾸고 싶으면, 위에 주소를 직접 수장하지 말고 .env.local 파일에 CONF_SERVER_URL를 추가해 주세요
//example : https://qa2.ringleserver.com/api/v4/student/matching/schedule

const teensV1 = (path) => urljoin(`${SERVER_URL}/api/teens/v1`, path)
const v4Student = (path) => urljoin(`${SERVER_URL}/api/v4/student`, path)
const v4Tutor = (path) => urljoin(`${SERVER_URL}/api/v4/tutor`, path)
const v4 = (path) => urljoin(`${SERVER_URL}/api/v4`, path)

const v5Student = (path) => urljoin(`${SERVER_URL}/api/v5/student`, path)
const v5AITutor = (path) => urljoin(`${SERVER_URL}/api/v5/student/ai_tutor`, path)
const v5 = (path) => urljoin(`${SERVER_URL}/api/v5`, path)

export const urlParams = (obj, nullable?) => {
  if (nullable) {
    return isEmpty(obj) ? '' : '?' + qs.stringify(obj)
  }

  const nilRemoved = omitBy(obj, overSome([isNil, isNaN]))

  if (isEmpty(nilRemoved)) {
    return ''
  }
  return '?' + qs.stringify(nilRemoved, { skipNulls: true })
}

const apis = {
  captcha: '/api/v1/captcha',
  slackNotification: '/api/v1/slackNotification',
  ttsStream: '/api/v1/tts-stream',
  textStream: '/api/v1/text-stream',
  text: '/api/v1/text',
  inquiry: '/api/v1/inquiry',
  geolocation: () => v4Student('/geolocation'),
  geolocation_by_ip: (ip) => v4Student(`/geolocation_by_ip?ip=${ip}`),
  track: () => v4Student(`/log`),
  track_visit: () => v4Student(`/log/visit`),
  track_click: () => v4Student(`/log/click`),
  track_lesson_guide: () => v4Student(`/log/check_lesson_guide`),
  track_ot: () => v4Student(`/log/register_orientation`),
  error: () => v4(`/page_view/error`),
  googleTranslate: () => v4(`/common/google_translate`),

  lesson_guide: {
    get: () => v4('/users/lesson_guide'),
  },
  company_educations: {
    get: () => v4('/users/company_educations'),
    conversation: () => v4('/users/company_educations/conversation'),
    aiTutor: () => v4('/users/company_educations/ai_tutor'),
  },
  contents_newsletter: {
    subscribe: () => v4Student(`/contents_newsletter/subscribe`),
    unsubscribe: () => v4Student(`/contents_newsletter/unsubscribe`),
    unsubscribe_reason: () => v4Student(`/contents_newsletter/unsubscribe_reason`),
  },
  auth: {
    me: () => v4('/users/me'),
    validate: {
      token: () => v4(`/admin/authenticate/jwt_token/validate`),
      email: () => v4(`/authenticate/validate`),
    },
    eventProps: {
      userInfo: () => v4('/users/event_properties'),
    },
    teens: () => v4('/users/teens'),
    notificationSettings: () => v4('/users/notification_settings'),
    notificationUpdate: (id, childId?) => v4(`/users/notification_setting_option/${id}`),
    notificationAllMktUpdate: () => v4(`/users/all_consent_mkt`),
    app: () => v4Student('/auth/app'),
    token: () => v4('/oauth/token'),
    signin: {
      socialAccount: () => v4('/accounts/signin/sns'), // will be deleted
      email: () => v4('/accounts/signin/email'), // will be deleted
    },
    signup: {
      tutor: () => v4(`/accounts/signup/tutor`),
      studentEmail: () => v4(`/accounts/signup/student/email`),
      studentSocialAccount: () => v4(`/accounts/signup/student/social_account`),
    },
    checkPromoCode: () => v4Student('/profile/promo'),
    checkReferralCode: () => v4Student('/profile/referral'),
    verifyPhoneNumber: () => v4Student('/profile/verify_phone_number'),
    changeLocale: () => v4(`/common/user_configuration/change_locale`),
    passwordReset: () => v4(`/common/authenticate/reset_password`),
    availableServices: () => v4(`/users/available_services`),
    password: {
      reset: () => v4(`/accounts/password/reset`),
      resetToken: {
        validate: () => v4(`/accounts/password/validate_reset_token`),
        create: () => v4(`/accounts/password/create_reset_token`),
      },
    },
  },
  ringleTeam: {
    departments: () => v4('/team/departments'),
    departmentsDetail: (id) => v4(`/team/departments/${id}`),
  },
  curation: {
    tutor: () => v4Student('/curations/tutor'),
    material: () => v4Student('/curations/material'),
    event: () => v4Student('/curations/event'),
  },
  home: {
    main: () => v4Student('/curation'),
    marketing: () => v4Student('/home/marketing'),
    todo: () => v4Student('/home/todo'),
    contents: {
      get: () => v4Student(`/contents`),
    },
  },
  personalization: () => v4Student('/curations/personalization'),
  myOriginal: () => v4Student('/my_original'),
  likeMaterial: () => v4Student('/my_original/course'),
  referral: (code) => v4Student(`/referral?referral_code=${code}`),
  invite: () => v4Student(`/invite`),
  inviteSummary: () => v4Student(`/invite/summary`),
  inviteHistory: () => v4Student(`/invite/history`),
  trial: {
    get: () => v4Student(`/trial`),
    summary: () => v4Student(`/trial/summary`),
    freeTrial: () => v4Student(`/trial/free_credit`),
    onboardingVideos: {
      get: () => v4Student(`/onboarding_videos`),
      update: (videoId: number) => v4Student(`/onboarding_videos/${videoId}`),
      complete: () => v4Student(`/onboarding_videos/complete`),
    },
    schedules: () => v4Student(`/trial/schedules`),
    tutors: () => v4Student(`/trial/tutors`),
    book: (classtime_id, tutor_id, duration) =>
      v4Student(`/trial/book${urlParams({ classtime_id, tutor_id, duration })}`),
    apply: (classtime_id, tutor_id, course_id, duration) =>
      v4Student(`/trial/apply${urlParams({ classtime_id, tutor_id, course_id, duration })}`),
    courses: () => v4Student(`/trial/courses`),
    complete: () => v4Student(`/trial/complete`),
    times: (date) => v4Student(`/trial/times?time=${date}`),
    time: () => v4Student(`/trial/time`),
    requestPhone: () => v4Student(`/trial/phone/request`),
    verify: () => v4Student(`/trial/phone/verify`),
    submit: () => v4Student(`/trial`),
    report: (lessonId, locale) => v5(`/caf/lessons/${lessonId}/summary${urlParams({ locale })}`),
    recommendedTutors: (tutorCount = 4) => v5Student(`/tutors/top_revisit_intention${urlParams({ tutorCount })}`),
    recommendedCourses: (caf: 'complexity' | 'fluency', score: number) =>
      v5Student(`courses/caf_recommendation${urlParams({ caf_type: caf, score })}`),
  },
  contentPrograms: {
    enroll: () => v4Student('/content_programs/enroll'),
    enrolled: () => v4Student('/content_programs/enrolled'),
    purchase: () => v4Student('/content_program_purchases'),
    getPurchase: (purchaseId) => v4Student(`/content_program_purchases/${purchaseId}`),
    purchaseCheckout: (purchaseId) => v4Student(`/content_program_purchases/${purchaseId}/checkout`),
    checkPaymentStripe: (paymentIntentId) =>
      v4Student(`/content_program_purchases/check_payment_stripe?payment_intent_id=${paymentIntentId}`),
    createStripeClientSecret: (id: number | string) =>
      v4Student(`/content_program_purchases/${id}/stripe_payment_intent`),
  },
  course: {
    coursekeyquestions: (course_id) => v5Student(`/courses/${course_id}/coursekeyquestions`),
    courses: () => v4Student('/courses'),
    interest: () => v5Student('/courses/interest'),
    course_category: () => v4Student(`/courses`),
    detail: ({ id }) => v4Student(`/course/${id}`),
    search: () => v4Student(`/courses/search`),
    favorite: () => v4Student(`/courses?filter=favorite`),
    conversation: () => v4Student('/courses?filter=conversation'),
    experienced: () => v4Student(`/matching/materials?filter=prev`),
    courseTopTutors: ({ materialId }) => v4Student(`/course/${materialId}/top_tutors`),
    show_tooltip: (key: string) => v4(`/users/preference/${key}`),
    edit_tooltip: (key: string, value: boolean) => v4(`/users/preference/${key}${urlParams({ value })}`),
  },
  notice: {
    policy: (locale, type) => v4Student(`/notice/policy?locale=${locale}&type=${type}`), //v1 tutor api pending 상태
  },
  landing: {
    topic: () => v4Student('/landing/course'),
    tutor: () => v4Student('/landing/tutor'),
    corrections: () => v4Student('/landing/correction'),
    partner: () => v4Student('/landing/partner'),
    tutors: (locale, purposeID) => v4Student(`/landing/tutors?locale=${locale}&purpose_id=${purposeID}`),
    courses: (locale, purposeID) => v4Student(`/landing/courses?locale=${locale}&purpose_id=${purposeID}`),
    profile: {
      purposes: (locale) => v4Student(`/landing/profile/studying_english_purposes?locale=${locale}`),
      interests: (locale) => v4Student(`/landing/profile/interest_keywords?locale=${locale}`),
      implements: (locale) => v4Student(`/landing/profile/focus_of_improvements?locale=${locale}`),
    },
  },
  faq: {
    welcome: () => v4Student(`/faq/welcome`),
  },
  classtimes: {
    get: () => v5Student(`/classtimes`),
    tutors: ({ classtime_id }) => v5Student(`/classtimes/${classtime_id}/tutors`),
    schedulePreference: (classtime_id) => v5Student(`/classtimes/${classtime_id}/schedule_preferences`),
    couponBundles: ({ classtime_id }) => v5Student(`/classtimes/${classtime_id}/coupon_bundles`),
    availableCoupon: ({ classtime_id }) => v5Student(`/classtimes/${classtime_id}/available_coupon`),
  },
  timeslots: {
    hold: ({ timeslotId }) => v5Student(`/timeslots/${timeslotId}/hold`),
    apply: () => v5Student(`/lessons/apply`),
  },
  onboarding: {
    get: () => v5Student(`/lessons/onboarding`),
    recommendLessons: () => v5Student(`/lessons/onboarding/recommend_lessons`),
  },
  myringle: {
    summary: () => v5Student(`/my_ringle/summary`),
    monthlyStatistics: () => v5Student(`/my_ringle/monthly_statistics`),
    statistics: () => v5Student(`/my_ringle/statistics`),
  },
  tutor: {
    timeslots: ({ tutorId }) => v5Student(`/tutors/${tutorId}/timeslots`),
    available: () => v5Student(`/tutors/available`),
    my: () => v4Student(`/tutor/my`),
    my_type: () => v4Student(`/tutor/my_type`),
    list: (page = 1, couponId?: number) => v4Student(`/tutor/list${urlParams({ page, coupon_type_id: couponId })}`),
    featured: (duration = 40) => v5Student(`/tutors/best_pref`),
    fitPurpose: () => v5Student(`/tutors/recommend`),
    strength: () => v5Student(`/tutors/get_tutor_strength_labels`),
    recommend: (page = 1, couponId?, size = 20) =>
      couponId
        ? v4Student(`/tutor/list?type=recommend&coupon_type_id=${couponId}&page=${page}&size=${size}`)
        : v4Student(`/tutor/list?type=recommend&page=${page}&size=${size}`),
    newTutors: (page = 1, couponId?, size = 20) =>
      couponId
        ? v4Student(`/tutor/list?type=recommend&coupon_type_id=${couponId}&page=${page}&size=${size}`)
        : v4Student(`/tutor/list?type=recommend&page=${page}&size=${size}`),
    newUnoccupiedTutors: (page = 1, size = 20) =>
      v4Student(`/tutor/list?type=new_unoccupied&page=${page}&size=${size}`),
    oldUnoccupiedTutors: (page = 1, size = 20) =>
      v4Student(`/tutor/list?type=old_unoccupied&page=${page}&size=${size}`),
    allTutors: (page = 1, couponId?, size = 20) =>
      couponId
        ? v4Student(`/tutor/list?type=all&coupon_type_id=${couponId}&page=${page}&size=${size}`)
        : v4Student(`/tutor/list?type=all&page=${page}&size=${size}`),
    myTutors: (page = 1, couponId?, size = 20) =>
      couponId
        ? v4Student(`/tutor/list?type=my&coupon_type_id=${couponId}&page=${page}&size=${size}`)
        : v4Student(`/tutor/list?type=my&page=${page}&size=${size}`),
    rookieTutors: ({ page, couponId, limit }: { page: number; couponId?: number; limit?: number }) =>
      couponId
        ? v4Student(`/tutor/list?type=rookie&coupon_type_id=${couponId}&page=${page}`)
        : limit
        ? v4Student(`/tutor/list?type=rookie&page=${page}&limit=${limit}`)
        : v4Student(`/tutor/list?type=rookie&page=${page}`),
    experiencedTutors: () => v4Student(`/tutor/experienced`),
    search: () => v4Student(`/tutor/search_tutors`),
    searchTutor: () => v5Student(`/tutors/search`),
    profile: (id, locale?, couponId?, referrer?) =>
      v4Student(
        `/tutor/profile${urlParams({
          tutor_id: id,
          locale: locale,
          coupon_type_id: couponId,
          referrer,
        })}`
      ),
    detail: () => v4Student(`/tutor/profile`),
    referralCode: {
      profile: (referralCode) =>
        v4Student(
          `/tutor/referral_code/profile${urlParams({
            referral_code: referralCode,
          })}`
        ),
    },
    add: () => v4Student('/tutor/add'),
    delete: () => v4Student('/tutor/delete'),
  },
  matching: {
    validateCoupon: () => v4Student('/matching/book_time_and_validate_coupon'),
    validateCouponForEdit: () => v4Student('/matching/book_time_and_validate_coupon_for_edit'),
    unbook: () => v4Student('/matching/unbook_time'),
    index: () => v4Student('/matching'),
    forEdit: (id) => v4Student(`/matching/for_edit/${id}`),
    schedule: () => v4Student('/matching/schedule'),
    scheduleAtTime: () => v4Student('/matching/schedule_at_time'),
    materials: {
      index: () => v4Student(`/matching/materials`),
      category: (categoryId) =>
        v4Student(
          `/matching/materials${urlParams({
            course_large_hashtag_id: categoryId,
          })}`
        ),
      filter: (filter) => v4Student(`/matching/materials${urlParams({ filter: filter })}`),
      filterObj: (filter) => v4Student(`/matching/materials${urlParams(filter)}`),
      search: (keyword, categoryId?) =>
        v4Student(
          `/matching/materials/search${urlParams({
            keyword: keyword,
            course_large_hashtag_id: categoryId,
          })}`
        ),
    },
  },
  trace: () => v4Student(`/trace`),
  lessons: {
    issueReportCategories: () => v4Student('/lesson/issue_report_categories'),
    issueReport: (lessonId) => v4Student(`/lesson/${lessonId}/issue_report`),
    apply: () => v4Student('/lesson/apply'),
    applyAuto: () => v4Student('/lesson/apply_auto'),
    enter: (id) => v4Student(`/lesson/upcoming/${id}/enter`),
    setting: (id) => v4Student(`/lesson_setting/${id}`),
    settingV5: ({ lessonId }) => v5Student(`/lessons/${lessonId}/lesson_settings`),
    startTimeLimit: () => v5Student(`/lessons/start_time_limit`),
    upcomings: () => v4Student(`/lesson/upcoming`),
    upcomingsV5: () => v5Student(`/lessons/upcoming`),
    upcomingsCount: () => v4(`/users/lesson_count/upcoming`),
    upcoming: (id) => v4Student(`/lesson/upcoming/${id}`),
    checkFirstPrestudy: () => v5Student(`/lessons/past/check_first_prestudy`),
    upcomingPrestudyInfo: (lessonId) => v5Student(`/lessons/${lessonId}/prestudy/info`),
    editCourse: () => v4Student('/lesson/edit/course'),
    editTimeForSelectMatching: () => v4Student('/lesson/edit/time_for_select_matching'),
    editTimeForAutoMatching: () => v4Student('/lesson/edit/time_for_auto_matching'),
    recommendTime: () => v5Student('lessons/recommend/time'),
    classroom: ({ lessonId }) => v4Student(`/lesson/classroom/${lessonId}`),
    zoom: ({ lessonId }) => v4Student(`/lesson/classroom/${lessonId}/zoom`),
    docs: ({ lessonId }) => v4Student(`/lesson/classroom/${lessonId}/docs`),
    pusherUrl: () => `${SERVER_URL}/pusher/auth_mobile`,
    chat: () => v4(`/rtc/talk_with_admin`),
    translate: (id) => v4Student(`lesson/classroom/${id}/translate`),
    exit: (id) => v4Student(`lesson/classroom/${id}/exit`),
    upload_link: (id) => teensV1(`/lessons/${id}/link_material`), // // 📍 teensV1 - 데이터독 호출 X, 코드 or 정책확인 필요
    upload_file: (id) => v4Student(`/lesson/${id}/file_material`),
    preparation: {
      get: ({ lessonId }: { lessonId: number }) => v4Student(`/lesson/preparation/${lessonId}`),
      getV5: ({ lessonId }: { lessonId: number }) => v5Student(`/lessons/${lessonId}/preparation`),
      answer: (id) => v4Student(`/lesson/preparation/${id}/answer`),
      answerV5: () => v5Student(`/answers`),
      option: (id) => v4Student(`/lesson/preparation/${id}/option`),
      prestudy: (id) => v4Student(`/lesson/preparation/${id}/prestudy`),
      google_translate: ({ text, mode, lesson_id }) =>
        v4(`/common/google_translate${urlParams({ text, mode, lesson_id })}`),
      delete: ({ answerId }: { answerId: number }) => v5Student(`/answers/${answerId}`),
      patch: ({ answerId }: { answerId: number }) => v5Student(`/answers/${answerId}`),
    },
    unassigned: {
      coupon: (id) => v4Student(`/lesson/${id}/available_coupons`),
      get: () => v4Student(`/lesson/unassigned`),
      take: (id = null) => v4Student(`/lesson/${id}/take`),
    },

    review: {
      past: () => v4Student(`/lesson/past`),
      pastV5: () => v5Student(`/lessons/past`),
      monthlyCount: () => v4Student('/lesson/past/monthly_count'),
      statistics: (month) => v4Student(`/statistics?month=${month}`),
      summary: () => v4Student(`/statistics/summary`),
      feedbackStatusV5: ({ lessonId }: { lessonId: number }) => v5Student(`/lessons/${lessonId}/feedback/status`),
      feedbackStats: (month, lesson_count) =>
        v4Student(`/statistics/feedback/chart?month=${month}&lesson_count=${lesson_count}`),
      feedbackByLesson: (month, lesson_id) => v4Student(`/statistics/feedback/${lesson_id}?month=${month}`),
      vocabStats: (month, lesson_count, duration) =>
        v4Student(
          `/statistics/voca/chart${urlParams({
            month,
            lesson_count,
            duration,
          })}`
        ),
      vocabByLesson: (month, lesson_id) => v4Student(`/statistics/voca/${lesson_id}?month=${month}`),
      wpmStats: (month, lesson_count) => v4Student(`/statistics/wpm/chart?month=${month}&lesson_count=${lesson_count}`),
      script: (lesson_id) => v4(`/student/lesson_record/${lesson_id}/script`),
      scriptStatusV5: ({ lessonId }: { lessonId: number }) => v5Student(`/lessons/${lessonId}/script/status`),
      phrase: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/phrase`),
      suggestion: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/suggestions`),
      feedback: (lesson_id, item_type = 'all') =>
        v4Student(`/lesson/past/${lesson_id}/feedback?item_type=${item_type}`),
      feedbackV5: (lesson_id) => v5Student(`/lessons/${lesson_id}/feedback`),
      feedbackComplimentsV5: (lesson_id) => v5Student(`/lessons/${lesson_id}/compliments`),
      feedbackNew: (lesson_id, isTeensLesson = false) =>
        isTeensLesson ? teensV1(`/lessons/${lesson_id}/feedback`) : v4Student(`/lesson/feedback/${lesson_id}`), // 📍 teensV1 - 데이터독 호출 O (튜터페이지에서 틴즈수업일 때 호출 되는 것으로 보임!)
      lateFeedback: (lesson_id) => v4Student(`/lesson/feedback/${lesson_id}/late`),
      analysis: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/analysis`),
      study: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/study`),
      eval: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/eval`),
      lessonInfo: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/web`),
      lessonInfoDetail: ({ lessonId }) => v4Student(`/lesson/past/${lessonId}/web`),
      postUseAnalysisUrl: () => v4(`/analysis/use`),
      late: (lesson_id) => v4Student(`/lesson/past/${lesson_id}/late`),
    },
  },
  review: {
    lessonReview: () => v4Student(`/lesson_review`),
  },
  credit: {
    list: () => v4Student('/credit'),
    my: () => v4('/credits/my'),
    summaryList: () => v4Student('/credit/list'),
    convert: () => v4Student('/credit/convert'),
    transfer: () => v4Student('/credit/transfer'),
    lessonHistory: (id) => v4Student(`/lesson_book?id=${id}`),
    receive: (id) => v4Student(`/lesson_book/credit?id=${id}`),
    // TEENS
    summary: () => teensV1('/credits/summary'), // 📍 teensV1 - 데이터독 호출 X / 디펜던시가 많아 삭제 보류
    // 수업권/증명서류
    couponBundles: () => v4Student('/coupon_bundles'),
    couponBundlesSummary: () => v4Student('/coupon_bundles/summary'),
    deductionGracedRecentlyUsed: () => v5Student('/deduction_grace_credits/recently_used'),
    deductionGraceCreditsSummary: ({ bundleId }: { bundleId: number }) =>
      v4Student(`/coupon_bundles/${bundleId}/deduction_grace_credits/summary`),
    weeklyLessonProgress: ({ bundleId }: { bundleId: number }) =>
      v4Student(`/coupon_bundles/${bundleId}/weekly_lesson_progress`),
    weeklyLessonPackageWarning: () => v4Student(`/coupon_bundles/weekly_lesson_package_warning`),
    couponBundlesDetails: ({ bundleId }: { bundleId: number }) => v4Student(`/coupon_bundles/${bundleId}`),
    // 수업권 연장
    couponExtendInfo: ({ bundleId }: { bundleId: number }) => v4Student(`/coupon_bundles/${bundleId}/extend_info`),
    couponExtendPurchase: {
      create: () => v4Student('/extend_purchase'),
      directPaymentRequest: (id: number | string) => v4Student(`/extend_purchase/${id}/direct_card_payment_request`),
      vbank: (id: number | string) => v4Student(`/extend_purchase/${id}/vbank`),
      createStripeClientSecret: (id: number | string) => v4Student(`/extend_purchase/${id}/stripe_payment_intent`),
      checkout: (id: number | string) => v4Student(`/extend_purchase/${id}/checkout`),
      paypalCheckout: () => v4Student('extend_purchase/checkout_paypal'),
    },
  },

  bonusCoupons: () => v5Student('/bonus_coupons'), //2024 08 27 쿠폰 박스 API 개편
  couponBox: {
    useCoupon: () => v4Student('/credit/coupon_box/used'),
  },
  challenge: {
    lesson_challenge: () => v4('/challenge_book/lesson_challenge'),
  },
  point: {
    list: () => v4Student('/points'),
    referrals: () => v5Student('/referrals'),
    compensate: ({ pointId }: { pointId: number }) => v4Student(`/points/${pointId}/redeem`),
    allPoint: (value: boolean) => v5Student(`settings/12/user_setting?value=${value}`),
    getAllPoint: () => v5Student('settings/12/user_setting'),
  },
  products: {
    customerType: () => v4Student('/customer_type'),
    list: () => v4Student('/product_lines'),
    b2c: () => v4Student('/product_lines/b2c'),
    b2b: () => v4Student('/product_lines/b2b'),
    productLineDetail: ({ productLineId }: { productLineId: number }) => v4Student(`/product_lines/${productLineId}`),
    productLineCompany: () => v4Student(`/product_lines/company`),
    productLineDiscountCoupon: ({ productLineId }: { productLineId: number }) =>
      v4Student(`/product_lines/${productLineId}/discount_coupon`),
    b2bList: () => v4Student('/product'),
    detail: ({ productId }: { productId: number }) => v4Student(`/product/${productId}`),
  },
  lesson: {
    get: (id) => v4Student(`/lesson/${id}`),
    cancel: (id) => v4Student(`/cancel/${id}`),
    cancelPolicy: (id) => v4Student(`/cancel/${id}/policy`),
    getShareAnswerV5: (answerId) => v5Student(`/lessons/answer/candidates?coursekeyquestion_id=${answerId}`),
    answerSelect: () => v4Student(`/lesson/answer/select`),
    like: () => v4Student('/lesson/answer/like'),
    unlike: () => v4Student('/lesson/answer/unlike'),
  },
  writing: {
    past: () => v4Student('/writing'),
    waiting: () => v4Student('/writing/waiting'),
    point: () => v4Student('/writing/point'),
    uploadFile: () => v4Student('/writing/upload_file'),
  },
  profile: {
    get: () => v4Student('/profile'),
    info: () => v4Student('/profile/info'),
    zoom: () => v4Student('/profile/zoom'),
    password: (id?: any) => v4Student(`/profile/password`),
    timezone: (id?: any) => v4Student('/profile/timezone'),
    leave: () => v4Student('/profile/leave'),
    updateNotifications: (id?: any, type?: any, value?: boolean) => v4Student('/profile/notifications'),
    updateProfileImage: (id?: any) => v4Student('/profile/avatar'),
    updateSetting: () => v4Student('/profile/notifications/setting'),
    phoneCodeRequest: (locale, id?: any) => v4Student(`/profile/encrypted_phone?locale=${locale}`),
    phoneCodeAuthorized: (locale) => v4Student(`/profile/code?locale=${locale}`),
    video: () => v4Student('/video/submit'),
  },
  purchase: {
    get: ({ purchaseId }: { purchaseId: string }) => v4Student(`/purchase/${purchaseId}`),
    check_payment_stripe: (id, secret) =>
      v4Student(`/purchase/check_payment_stripe?payment_intent_id=${id}&payment_intent_client_secret=${secret}`),
    check_payment_stripe_setup: (id, secret) =>
      v4Student(`/purchase/check_payment_stripe?setup_intent_id=${id}&setup_intent_client_secret=${secret}`),
    vbank: () => v4Student('/purchase/vbank'),
    certificate: (id) => v4Student(`/purchase/${id}/certificate`),
    getLessons: (id?: any, start_date?: string, end_date?: string, children?: boolean) =>
      v4Student(
        `/purchase/${id}/lessons${urlParams({
          start_date: start_date,
          end_date: end_date,
          children: children,
        })}`
      ),
    previous: ({ productId }: { productId: number }) =>
      v4Student(`/product_lines/${productId}/previous_partial_purchase`),
  },
  purchases: {
    iamportWebhook: () => v4Student('/purchases/iamport_webhook'),
    detail: (id) => v4Student(`/purchases/${id}`),
    create: () => v4Student(`/purchases`),
    createStripeClientSecret: (id: number | string) => v4Student(`/purchases/${id}/stripe_payment_intent`),
    directPaymentRequest: (id: number | string) => v4Student(`/purchases/${id}/direct_card_payment_request`),
    updateAmount: (id: number | string) => v4Student(`/purchases/${id}/update_amount`),
    checkout: (id: number | string) => v4Student(`/purchases/${id}/checkout`),
    vbank: (id: number | string) => v4Student(`/purchases/${id}/vbank`),
    latest_pay_method: () => v4Student('/purchases/latest'),
  },
  certificate: {
    detail: (id) => v4Student(`/certificate/${id}`),
    print: (id) => v4Student(`/certificate/${id}/print`),
  },
  wrpurchase: {
    create: () => v4Student('/wrpurchase/create'),
    check_payment: () => v4Student('/wrpurchase/check_payment'),
  },
  evaluation: {
    post: (id) => v4Student(`/evaluation/${id}`),
    keywords: () => v4Student('/evaluation/keywords'),
  },
  history: {
    check: () => v4Student('/log/check'),
    monthly: (year: string, month: string, logCategory?: string) =>
      v4Student(`/log/monthly${urlParams({ year, month, log_category: logCategory })}`),
    category: (startDate: string, endDate: string, logCategory?: string) =>
      v4Student(
        `/log${urlParams({
          start_date: startDate,
          end_date: endDate,
          log_category: logCategory,
        })}`
      ),
    validate: (lessonId) => v4Student(`/log/${lessonId}/validate`),
  },
  promotion: {
    gnb: () => v4('/gnb'),
    popup: () => v4('/popup'),
    programs: () => v4Student('/events/programs'),
    applyProgram: (id) => v4Student(`/events/programs/${id}`),
    applyStudyClub: (id) => v4Student(`/events/study_clubs/${id}`),
    tickets: () => v5Student(`/promotions/entry_tickets`),
    ticketStatus: () => v5Student(`/promotions/entry_tickets/status`),
    ticketRedeem: () => v5Student(`/promotions/entry_tickets/redeem`),
  },
  referralRecord: {
    get: () => v4('/referral_record'),
    detail: (id) => v4(`/referral_record/${id}`),
  },
  biteSizeFeedbacks: {
    tester: () => v4('/bite_size_feedbacks/tester'),
    userReport: () => v4('/bite_size_feedbacks/user_report'),
    track: () => v4Tutor('/event_track/publish'),
  },
  study: {
    weekInfo: () => v5Student('/study/week_info'),
    weekdaysInfo: () => v5Student('/study/weekdays_info'),
    activityDescription: ({ targetDate }) => v5Student(`/study/activities?date=${targetDate}`),
    recommendedMaterials: () => v5Student('/study/recommended_materials'),
    completeActivity: () => v5Student('/study/activity-set/complete'),
    contentPrograms: () => v5Student('/content_program_enrollments/content_programs'),
    activity: {
      SPEAKING_SHADOWING: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/speaking/shadowing/${activityId}`),
        complete: ({ activityId }) => v5Student(`/study/activities/speaking/shadowing/${activityId}`),
      },
      GRAMMAR_LISTEN_FILL: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/grammar/listen-fill/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activities/grammar/listen-fill/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activities/grammar/listen-fill/${activityId}/feedbacks`),
      },
      GRAMMAR_CHOOSE_CORRECT: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/grammar/choose-correct/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activities/grammar/choose-correct/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activities/grammar/choose-correct/${activityId}/feedbacks`),
      },
      GRAMMAR_READ_FILL: {
        initialInfo: ({ activityId }) => v5Student(`/study/activity/GRAMMAR_READ_FILL/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activity/GRAMMAR_READ_FILL/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activity/GRAMMAR_READ_FILL/${activityId}/feedbacks`),
      },
      GRAMMAR_PICK_SENTENCE: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/grammar/pick-sentence/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activities/grammar/pick-sentence/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activities/grammar/pick-sentence/${activityId}/feedbacks`),
      },
      WRITING_TRANSLATE: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/writing/translate/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activities/writing/translate/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activities/writing/translate/${activityId}/feedbacks`),
      },
      WRITING_QUICK_REPLY: {
        initialInfo: ({ activityId }) => v5Student(`/study/activity/WRITING_QUICK_REPLY/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activity/WRITING_QUICK_REPLY/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activity/WRITING_QUICK_REPLY/${activityId}/feedbacks`),
      },
      WRITING_BRIEF_WRITE: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/writing/brief-write/${activityId}`),
        submitAnswer: ({ activityId }) => v5Student(`/study/activities/writing/brief-write/${activityId}`),
        feedback: ({ activityId }) => v5Student(`/study/activities/writing/brief-write/${activityId}/feedbacks`),
      },
      SHARE: {
        initialInfo: ({ activityId }) => v5Student(`/study/activities/writing/share/${activityId}`),
        submitAnswer: () => v5Student(`/answers`),
        feedback: ({ activityId }) => v5Student(`/study/activities/writing/share/${activityId}/feedbacks`),
        complete: ({ activityId }) => v5Student(`/study/activities/writing/share/${activityId}`),
      },
    },
    activitySet: {
      resetActivitySet: () => v5Student(`/study/activity-set`),
      getNextActivityInfo: () => v5Student(`/study/activity-set/next-activity`),
      completeActivity: ({ activityId }) => v5Student(`/study/activities/${activityId}/complete`),
      skipActivity: ({ activityId }) => v5Student(`/study/activities/${activityId}/skip`),
      userReport: ({ activitySetId }) => v5Student(`/study/activity-set/${activitySetId}/user-report`),
    },
  },
  conversation: {
    creditBundle: {
      summary: ({ bundleId }) => v5Student(`/conversation/credit_bundles/${bundleId}/summary`),
      threads: ({ bundleId }) => v5Student(`/conversation/credit_bundles/${bundleId}/threads`),
    },
    credits: {
      summary: () => v5Student('/conversation/credits/summary'),
    },
    threads: {
      index: ({ date } = { date: undefined }) =>
        date
          ? v5Student(`/conversation/threads?month=${getMonth(date) + 1}&year=${getYear(date)}`)
          : v5Student(`/conversation/threads`),
      updateGoal: (thread_id) => v5Student(`/conversation/threads/${thread_id}/goal`),
      summary: () => v5Student('/conversation/threads/summary'),
      status: ({ date, status }) =>
        v5Student(`/conversation/threads?status=${status}&month=${getMonth(date) + 1}&year=${getYear(date)}`),
      courses: () => v5Student(`/conversation/threads/courses`),
      instruction: ({ courseId }) => v5Student(`/conversation/threads/instruction?course_id=${courseId}`),
      messages: ({ thread_id }) => v5Student(`/conversation/threads/${thread_id}/messages`),
      message: (thread_id) => v5Student(`/conversation/threads/${thread_id}/message`),
      detail: (thread_id) => v5Student(`/conversation/threads/${thread_id}/detail`),
      messageCount: ({ threadId }: { threadId: number }) => v5Student(`/conversation/threads/${threadId}/summary`),
      complete: (thread_id) => v5Student(`/conversation/threads/${thread_id}/complete`),
      feedback: (thread_id) => v5Student(`/conversation/threads/${thread_id}/feedback`),
      analysis: (thread_id) => v5Student(`/conversation/threads/${thread_id}/analysis`),
      audio: (thread_id, role?: string, idx?: number) =>
        v5Student(
          `/conversation/threads/${thread_id}/audio${urlParams({
            role,
            idx,
          })}`
        ),
    },
    users: {
      getSetting: (key: string) => v4(`/users/preference/${key}`),
      updateSetting: (key: string, value: boolean) => v4(`/users/preference/${key}${urlParams({ value })}`),
    },
    setting: {
      index: () => v5Student(`/conversation/settings`),
      speed: (value) => v5Student(`/conversation/settings/speed?value=${value}`),
      difficulty: (value) => v5Student(`/conversation/settings/difficulty?value=${value}`),
    },
    token: {
      speech: () => v5Student(`/conversation/token/speech`),
    },
  },
  aiTutor: {
    jobs: {
      user: () => v5Student(`/ai_tutor/jobs/user`),
      list: () => v5Student(`/ai_tutor/jobs/list`),
      category: ({ jobId }) => v5Student(`/ai_tutor/jobs/category?job_id=${jobId}`),
    },
    course: {
      list: ({ jobId, jobCategoryId }) =>
        v5Student(`/ai_tutor/courses${urlParams({ job_id: jobId, job_category_id: jobCategoryId })}`),
      detail: ({ courseId }) => v5Student(`/ai_tutor/courses/${courseId}`),
      record: ({ courseId }) => v5Student(`/ai_tutor/courses/${courseId}/record`),
    },
    roleplay: {
      list: ({ jobId, jobCategoryId }) =>
        v5Student(`/ai_tutor/roleplay${urlParams({ job_id: jobId, job_category_id: jobCategoryId })}`),
      detail: ({ roleplayId }) => v5Student(`/ai_tutor/roleplay/${roleplayId}`),
    },
    lesson: {
      detail: ({ lessonId }) => v5Student(`/ai_tutor/lesson/${lessonId}`),
      progress: ({ lessonId }) => v5Student(`/ai_tutor/lesson/${lessonId}/progress`),
    },
    streak: () => v5Student(`/ai_tutor/streak`),
    job: {
      detail: ({ jobId }) => v5Student(`/ai_tutor/jobs/category?job_id=${jobId}`),
    },
    summary: {
      get: () => v5Student(`/ai_tutor/summary`),
      streak: () => v5Student(`/ai_tutor/summary/streak`),
      course: () => v5Student(`/ai_tutor/summary/course`),
      membership: ({ service }) => v5Student(`/ai_tutor/summary/membership?service=${service}`),
    },
    pronunciation: {
      token: () => v5Student(`/ai_tutor/pronunciation`),
    },
  },
  caf: {
    report: {
      highlight: (id) => v5(`/caf/highlights/details/${id}/reports`),
      metric: (id) => v5(`/caf/analysis_components/details/${id}/reports`),
    },
    settings: {
      item: (lessonId: number) => v5(`/caf/settings/${lessonId}`),
      pin: () => v5(`/caf/settings/pin`),
    },
    summary: {
      dates: () => v5(`/caf/summary/dates`),
      overview: () => v5(`/caf/summary/overview`),
      lessons: (start_date?: string, end_date?: string, page?: number, size?: number) =>
        v5(`/caf/summary/lessons${urlParams({ start_date, end_date, page, size })}`),
      highlights: {
        list: () => v5(`/caf/summary/highlights`),
        tag: (tag: number, { startDate, endDate, pageParam }, size) =>
          v5(
            `/caf/summary/highlights/${tag}${urlParams({
              start_date: startDate,
              end_date: endDate,
              page: pageParam,
              size,
            })}`
          ),
      },
      setting: (id: number, status: boolean) => {
        return v5(`caf/settings/${id}?allow_summary=${status}`)
      },
      average: () => {
        return v5(`caf/scores`)
      },
    },
    status: {
      check: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/status`),
      diff: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/band_diffs`),
      diffFeedback: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/feedback`),
    },
    popup: {
      overview: {
        check: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/checked`),
        cancel: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/cancel_popup`),
      },
      summary: {
        cancel: () => v5(`/caf/summary/cancel_popup`),
      },
    },
    analysisComponents: {
      list: ({ lessonId }: { lessonId: number }) => v5(`/caf/lessons/${lessonId}/analysis_components`),
      detail: {
        summary: ({ componentId }: { componentId: number }) => v5(`/caf/analysis_components/${componentId}`),
        details: ({ componentId }: { componentId: number }) => v5(`/caf/analysis_components/${componentId}/details`),
        highlights: (componentId: number) => v5(`/caf/analysis_components/${componentId}/highlights`), //삭제
      },
    },
    metric: {
      detail: (metricId: number, tag: number, page = 1, size: number) => {
        return v5(`/caf/metrics/${metricId}/details${urlParams({ tag, page, size })}`)
      },
    },
    highlight: {
      list: (lessonId) => v5(`/caf/lessons/${lessonId}/highlights`), //per lesson
      detail: (highlightId: number, page = 1, size = 30) => {
        return v5(`/caf/highlights/${highlightId}${urlParams({ page, size })}`)
      },
      pin: ({ tag, active }) => v5(`/caf/settings/pin${urlParams({ caf_highlight_tag: tag, active })}`),
      ai: {
        paraphrase: (highlightId: number) => v5(`/caf/highlights/details/${highlightId}/corrections`),
        feedback: (correctionId: number) => v5(`/caf/highlights/details/corrections/${correctionId}/feedback`),
      },
    },
    jobAnalysis: {
      jobId: () => v5(`/caf/segment/job`),
      options: () => v5Student(`/profile/jobs/options`),
      submit: () => v5Student(`/profile/jobs`),
      status: () => v5(`/caf/segment/status`),
      graphData: ({ jobId }: { jobId: number }) => v5(`/caf/segment/graph_data?job_id=${jobId}`),
      levelData: ({ jobId }: { jobId: number }) => v5(`/caf/segment/level_data?job_id=${jobId}`),
      showBanner: (key: string) => v4(`/users/preference/${key}`),
      editBanner: (key: string, value: boolean) => v4(`/users/preference/${key}${urlParams({ value })}`),
    },
    scores: () => v5(`/caf/scores`),
  },
  caf_request: {
    analysisComponents: {
      list: ({ threadId }: { threadId: number }) => v5(`/caf/threads/${threadId}/analysis_components`),
    },
    highlight: {
      list: (threadId) => v5(`/caf/threads/${threadId}/highlights`),
    },
  },
  share: {
    challenge: ({ challengeId, optionId, locale }) =>
      v5Student(`/challenge_campaigns/${challengeId}/options/${optionId}/og?locale=${locale}`),
  },
}

export const tutor_apis = {
  notice: {
    detail: (id) => v4Tutor(`/notice/${id}`),
  },
}

export const webview_apis = {
  caf: {
    report: {
      highlight: (id) => v4(`/caf/highlights/details/${id}/reports`),
      metric: (id) => v4(`/caf/analysis_components/details/${id}/reports`),
    },
    settings: {
      item: (lessonId: number) => v4(`/caf/settings/${lessonId}`),
      pin: () => v4(`/caf/settings/pin`),
    },
    summary: {
      dates: () => v4(`/caf/summary/dates`),
      overview: () => v4(`/caf/summary/overview`),
      lessons: (start_date?: string, end_date?: string, page?: number, size?: number) =>
        v4(`/caf/summary/lessons${urlParams({ start_date, end_date, page, size })}`),
      highlights: {
        list: () => v4(`/caf/summary/highlights`),
        tag: (tag: number, { startDate, endDate, pageParam }, size) =>
          v4(
            `/caf/summary/highlights/${tag}${urlParams({
              start_date: startDate,
              end_date: endDate,
              page: pageParam,
              size,
            })}`
          ),
      },
      setting: (id: number, status: boolean) => {
        return v4(`caf/settings/${id}?allow_summary=${status}`)
      },
      average: () => {
        return v4(`caf/scores`)
      },
    },
    status: {
      check: ({ lessonId }: { lessonId: number }) => v4(`/caf/lessons/${lessonId}/status`),
      diff: ({ lessonId }: { lessonId: number }) => v4(`/caf/lessons/${lessonId}/band_diffs`),
    },
    popup: {
      overview: {
        check: ({ lessonId }: { lessonId: number }) => v4(`/caf/lessons/${lessonId}/checked`),
        cancel: ({ lessonId }: { lessonId: number }) => v4(`/caf/lessons/${lessonId}/cancel_popup`),
      },
      summary: {
        cancel: () => v4(`/caf/summary/cancel_popup`),
      },
    },
    analysisComponents: {
      list: ({ lessonId }: { lessonId: number }) => v4(`/caf/lessons/${lessonId}/analysis_components`),
      detail: {
        summary: ({ componentId }: { componentId: number }) => v4(`/caf/analysis_components/${componentId}`),
        details: ({ componentId }: { componentId: number }) => v4(`/caf/analysis_components/${componentId}/details`),
        highlights: (componentId: number) => v4(`/caf/analysis_components/${componentId}/highlights`), //삭제
      },
    },
    metric: {
      detail: (metricId: number, tag: number, page = 1, size: number) => {
        return v4(`/caf/metrics/${metricId}/details${urlParams({ tag, page, size })}`)
      },
    },
    highlight: {
      list: (lessonId) => v4(`/caf/lessons/${lessonId}/highlights`), //per lesson
      detail: (highlightId: number, page = 1, size = 30) => {
        return v4(`/caf/highlights/${highlightId}${urlParams({ page, size })}`)
      },
      pin: ({ tag, active }) => v4(`/caf/settings/pin${urlParams({ caf_highlight_tag: tag, active })}`),
      ai: {
        paraphrase: (highlightId: number) => v4(`/caf/highlights/details/${highlightId}/corrections`),
        feedback: (correctionId: number) => v4(`/caf/highlights/details/corrections/${correctionId}/feedback`),
      },
    },
    scores: () => v4(`/caf/scores`),
    show_tooltip: (key: string) => v4(`/users/preference/${key}`),
    edit_tooltip: (key: string, value: boolean) => v4(`/users/preference/${key}${urlParams({ value })}`),
  },
}

export const ai_tutor_apis = {
  pricings: {
    get: () => v5AITutor(`/pricings`),
    product_ids: () => v5AITutor('/pricings/product_ids'),
    eligibility: () => v5AITutor('/pricings/eligibility'),
  },
  purchases: {
    post: () => v5AITutor('/purchases'),
    by_merchant_uid: () => v5AITutor('/purchases/by_merchant_uid'),
    check_purchase_success: () => v5AITutor('/purchases/check_purchase_success'),
  },
  iap_uuid: {
    get: () => v4('users/uuid'),
  },
}

export default apis
