// @ts-strict-ignore
import ImageUrlBuilder from '@sanity/image-url'
import { compareAsc, setDate, setHours, setMinutes, setMonth, setSeconds, setYear } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import Router, { NextRouter } from 'next/router'
import { Cookies } from 'react-cookie'
import { LOCALE_EN, LOCALE_KEY, LOCALE_KO, localeIndex } from '../modules/i18n/config'
import { COOKIE_LIST, COOKIE_OPT, CURRENCY_LIST, DOLLARS_TO_KRW, TODO_TYPES } from '../modules/vars'
import { RINGLE_DOMAIN } from './envVars'
import { CustomRouter, Url } from './hooks/common/useAppRouter'
import { getInitialLocale } from './i18n/getInitialLocale'
import client from './sanityClient'

export function getCurrency(countryCode) {
  const currencyIdx = CURRENCY_LIST.findIndex((currency) => currency.country_code === countryCode)
  return currencyIdx === -1 ? 'KRW' : CURRENCY_LIST[currencyIdx].name
}

export function isWithIn24HoursUser(currentUser) {
  if (!currentUser) return false

  const dateString = currentUser?.braze?.private?.signup_date
  const targetDate = new Date(dateString).getTime()
  const now = new Date().getTime()
  const difference = now - targetDate

  const hoursDifference = difference / (1000 * 60 * 60)

  return hoursDifference <= 24
}

export function add24Hours(dateString) {
  const date = new Date(dateString)
  const result = new Date(date.getTime() + 24 * 60 * 60 * 1000)

  return result
}

export const detectInAppBrowser = (agent) => {
  const inAppRegex = [
    /KAKAOTALK/i,
    /Instagram/i,
    /NAVER/i,
    /zumapp/i,
    /Whale/i,
    /Snapchat/i,
    /Line/i,
    /everytimeApp/i,
    /Whatsapp/i,
    /Electron/i,
    /wadiz/i,
    /AliApp/i,
    /FB_IAB/i,
    /FB4A/i,
    /FBAN/i,
    /FBIOS/i,
    /FCSS/i,
    /SamsungBrowser/i,
  ]

  return inAppRegex.some((mobile) => agent.match(mobile))
}

export const currencyFormat = (number) => {
  return new Intl.NumberFormat().format(number)
}
export function financial(x, fixed = 2) {
  return Number.parseFloat(x).toFixed(fixed)
}

export function finishedFirstClassUser(currentUser) {
  return currentUser && currentUser.profile?.total_lesson > 0
}
export function firstUser(currentUser) {
  return !currentUser || (currentUser && currentUser.profile?.total_amount == 0)
}
export function paidUser(currentUser) {
  return currentUser && currentUser.total_amount > 0
}
export function completeTrialUser(currentUser) {
  return currentUser && currentUser.trial_complete
}
export function priceWithLocale(isKo, price) {
  return isKo ? `${currencyFormat(price)}원` : `$${financial(price / DOLLARS_TO_KRW)}`
}
export function urlFor(source: string) {
  const builder = ImageUrlBuilder(client)
  return builder.image(source)
}
export function calculateByMathMethod(mathMethod: MATH_METHOD, value) {
  switch (mathMethod) {
    case MATH_METHOD.ROUND:
      value = Math.round(value)
      break
    case MATH_METHOD.CEIL:
      value = Math.round(value)
      break
    case MATH_METHOD.FLOOR:
      value = Math.round(value)
      break
  }
  return value
}
export function priceWithCountryCode(
  price,
  country_code,
  converted = true,
  point = false,
  locale: any = LOCALE_KO,
  mathMethod = null
) {
  const exchange_rate = getExchangeRate(country_code)
  if (!converted) price = price / exchange_rate

  if (mathMethod) {
    price = calculateByMathMethod(mathMethod, price)
  }

  switch (country_code) {
    case 'KR':
      price = `${currencyFormat(price)}`
      if (!point) {
        if (locale == LOCALE_KO) {
          price += '원'
        } else {
          price = '₩' + price
        }
      }
      break
    case 'JP':
      price = `${currencyFormat(price)}円`
      break
    case 'VN':
      price = Intl.NumberFormat('vi', { style: 'currency', currency: 'VND' }).format(price)
      break
    default:
      if (point) {
        price = Math.round(price * 100) / 100
      } else {
        price = Math.round(price)
      }
      price = `$${currencyFormat(price)}`
      break
  }
  return price
}
export function languageByCountryCode(countryCode) {
  switch (countryCode) {
    case 'KR':
      return LOCALE_KO
    default:
      return LOCALE_EN
  }
}
export const convertPrice = (price, country_code, point = true) => {
  switch (country_code) {
    case 'KR':
      break
    case 'JP':
    case 'TW':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case 'CN':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 100) * 100
      }
      break
    case 'VN':
      if (point) {
        price = Math.floor(price)
      } else {
        price = Math.round(price / 1000) * 1000
      }
      break
    default:
      if (point) {
        price = Math.floor(price * 100) / 100
      } else {
        price = Math.round(price)
      }
      break
  }
  return price || 0
}
export const getExchangeRate = (countryCode) => {
  switch (countryCode) {
    case 'KR':
      return 1
    case 'JP':
    case 'VN':
    case 'TW':
    case 'CN':
    case 'US':
      return DOLLARS_TO_KRW
    default:
      return DOLLARS_TO_KRW
  }
}

export function setAdHistory(query) {
  if (!query) {
    return
  }

  const cookies = new Cookies()
  const utm_ad_info = cookies.get(COOKIE_LIST.UTM_AD_INFO)
  const { utm_source = '', utm_medium = '', utm_term = '', utm_campaign = '', utm_content = '' } = query
  let str = ''
  let count = 0
  if (utm_ad_info == '' || utm_ad_info == undefined) {
    str = '#1-rg'
    count = 1
  } else {
    count = parseInt(utm_ad_info.substring(0, 2)[1])
    str = `#${count + 1}-rg`
  }
  if (count != 0) {
    if (utm_source != '') {
      str += `&utm_source=${utm_source}`
    }
    if (utm_medium != '') {
      str += `&utm_medium=${utm_medium}`
    }
    if (utm_term != '') {
      str += `&utm_term=${utm_term}`
    }
    if (utm_campaign != '') {
      str += `&utm_campaign=${utm_campaign}`
    }
    if (utm_content != '') {
      str += `&utm_content=${utm_content}`
    }
    // 2021-01-24 10:35:42 +0000(UTC기준)
    const now = new Date()
    const utcDate = utcToZonedTime(now, 'UTC')
    const formattedNow = format(utcDate, 'yyyy-MM-dd HH:mm:ss X', { timeZone: 'UTC' })
    if (str.length > 5) {
      str += `&time=${formattedNow}`
      cookies.set(COOKIE_LIST.UTM_AD_INFO, str + `${utm_ad_info ? utm_ad_info : ''}`, COOKIE_OPT)
    }
  }
}

export function getSupportedMimeTypes() {
  const VIDEO_TYPES = ['webm', 'ogg', 'mp4', 'x-matroska']
  const VIDEO_CODECS = ['vp9', 'vp9.0', 'vp8', 'vp8.0', 'avc1', 'av1', 'h265', 'h.265', 'h264', 'h.264', 'opus']

  const supportedTypes = []
  VIDEO_TYPES.forEach((videoType) => {
    const type = `video/${videoType}`
    VIDEO_CODECS.forEach((codec) => {
      const variations = [
        `${type};codecs=${codec}`,
        `${type};codecs:${codec}`,
        `${type};codecs=${codec.toUpperCase()}`,
        `${type};codecs:${codec.toUpperCase()}`,
        `${type}`,
      ]
      variations.forEach((variation) => {
        if (MediaRecorder.isTypeSupported(variation)) supportedTypes.push(variation)
      })
    })
  })
  return supportedTypes
}

export function getWeekNumber(d: Date) {
  const targetDate = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
  targetDate.setUTCDate(targetDate.getUTCDate() + 4 - (targetDate.getUTCDay() || 7))
  const yearStart = new Date(Date.UTC(targetDate.getUTCFullYear(), 0, 1))
  const weekNo = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)
  return [targetDate.getUTCFullYear(), weekNo]
}

export function getMonthName(d) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const shortMonthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  return [monthNames[d], shortMonthNames[d]]
}

export const wordCount = (text) => {
  text = text?.trim() || ''
  return text.length > 0 ? text.split(/\s+/).length : 0
}

export const getDday = (date) => {
  if (!date) return 0
  const now = new Date().getTime()
  const remainTime = date - now
  return Math.floor(remainTime / (1000 * 60 * 60 * 24))
}

export const getBrowser = (useragent) => {
  let browser = ''
  if (useragent.indexOf('FBAN') > -1 || useragent.indexOf('FBAV') > -1) {
    browser = 'facebook'
  } else if (useragent.indexOf('Instagram') > -1) {
    browser = 'instagram'
  } else if (useragent.indexOf('KAKAOTALK') != -1) {
    browser = 'kakaotalk'
  } else {
    return null
  }

  return {
    browser: browser,
  }
}

export const isBirthdayInvalid = (_year: string, _month: string, _date: string, timezone: string) => {
  const year = _year === '' ? -1 : Number(_year)
  const month = _month === '' ? -1 : Number(_month)
  const date = _date === '' ? -1 : Number(_date)

  const refDate = new Date(year, month - 1, date)
  if (
    (refDate.getFullYear() > timezonedNow(timezone).getFullYear() &&
      (refDate.getMonth() + 1 === month || month === -1)) ||
    refDate.getFullYear() < timezonedNow(timezone).getFullYear() - 120
  )
    return 'year'
  else if (
    refDate.getFullYear() !== year &&
    (refDate.getDate() === date || date === -1) // 12월 45일일 때 'month'로 표시되는 에러 수정
  )
    return 'month'
  else if (refDate.getMonth() + 1 !== month) return 'day'
  else if (refDate.getFullYear() > timezonedNow(timezone).getFullYear()) return 'year'
  else return null
}

export function redirect(ctx, location) {
  const status = 302
  if (ctx.res) {
    // Seems to be the version used by zeit
    ctx.res.writeHead(status, {
      Location: location,
      'Content-Type': 'text/html; charset=utf-8',
    })
    ctx.res.end()
    return
  }
  Router.replace(location)
}

export const formatTime = (time: number) => {
  let minutes, seconds
  minutes = Math.floor(time / 60)
  minutes = minutes >= 10 ? minutes : '0' + minutes
  seconds = Math.floor(time % 60)
  seconds = seconds >= 10 ? seconds : '0' + seconds
  return minutes + ':' + seconds
}

export const remainTime = (time, comparingTime) => {
  const total = Date.parse(time) - comparingTime
  const seconds = Math.floor((total / 1000) % 60)
  const minutes = Math.floor((total / 1000 / 60) % 60)
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
  const days = Math.floor(total / (1000 * 60 * 60 * 24))

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  }
}

export const locationMove = (router: NextRouter | CustomRouter, url: string) => {
  if (router.query.lang === LOCALE_KEY) {
    router.push(`/${LOCALE_KEY}${url}`)
  } else {
    router.push(`/${getInitialLocale()}${url}`)
  }
}
export const locationMoveLocale = (router: NextRouter | CustomRouter, url: string, config?: { shallow?: boolean }) => {
  if (
    url.startsWith('/en/') ||
    url.startsWith('/ko/') ||
    url.startsWith('/zh_chs/') ||
    url.startsWith('/zh_cht/') ||
    url.startsWith('/ja/')
  ) {
    router.push(url, undefined, config)
  } else if (url.includes('[lang]')) {
    router.push(url.replace('[lang]', getInitialLocale()), undefined, config)
  } else {
    router.push(`/${getInitialLocale()}${url}`, undefined, config)
  }
}
export const locationReplaceLocale = (router: NextRouter, url: string | Url, config?: { shallow?: boolean }) => {
  if (typeof url == 'string') {
    if (url.startsWith('/en/') || url.startsWith('/ko/')) {
      router.replace(url, undefined, config)
    } else if (url.includes('[lang]')) {
      router.replace(url.replace('[lang]', getInitialLocale()), undefined, config)
    } else {
      router.replace(`/${getInitialLocale()}${url}`, undefined, config)
    }
  } else {
    router.replace(url, undefined, config)
  }
}

export const locationMoveLocaleNewTab = (url) => {
  window.open(`/${getInitialLocale()}${url}`, '_blank')
}

export const removeArray = (arr, value) => {
  return arr.filter((item) => {
    return item != value
  })
}

export const sumOfArray = (arr) => {
  if (arr.length == 0) return 0
  const sum = arr.reduce(function (accumulator, currentValue) {
    return accumulator + currentValue
  })
  return sum
}

export const twoDigitNumber = (n) => {
  n = String(n)
  if (n.length == 1) n = '0' + n
  return n
}

//timezoned date utils
export function timezonedDate(date, timezone) {
  return zonedTimeToUtc(date, timezone)
}
export function timezonedNow(timezone = 'Asia/Seoul') {
  const now = new Date()
  return utcToZonedTime(now, timezone)
}

export const difference2Parts = (milliseconds) => {
  const secs = Math.floor(Math.abs(milliseconds) / 1000)
  const mins = Math.floor(secs / 60)
  const hours = Math.floor(mins / 60)
  const days = Math.floor(hours / 24)
  const millisecs = Math.floor(Math.abs(milliseconds)) % 1000
  const multiple = (term, n) => (n !== 1 ? `${n} ${term}s` : `1 ${term}`)

  return {
    days: days,
    hours: hours % 24,
    hoursTotal: hours,
    minutesTotal: mins,
    minutes: mins % 60,
    seconds: secs % 60,
    secondsTotal: secs,
    milliSeconds: millisecs,
    get diffStr() {
      return `${multiple(`day`, this.days)}, ${multiple(`hour`, this.hours)}, ${multiple(
        `minute`,
        this.minutes
      )} and ${multiple(`second`, this.seconds)}`
    },
    get diffStrMs() {
      return `${this.diffStr.replace(` and`, `, `)} and ${multiple(`millisecond`, this.milliSeconds)}`
    },
  }
}

//  Time Utils
export const timeMilSec = {
  second: 1000,
  minute: 1000 * 60,
  hour: 1000 * 60 * 60,
}
export const stringHours = (hours) => (hours < 10 ? '0' + String(hours) : hours)
export const stringMinutes = (minutes) => (minutes < 10 ? '0' + String(minutes) : minutes)

// AWS BUCKET
const img_addr1 = 'd2mkevusy1mb28.cloudfront.net'
const img_addr2 = 'd38emex6h5e12i.cloudfront.net'

const convertResizableUrl = (url) => url.replace(img_addr1, img_addr2)

export const cleanUri = (url) => url.split('?')[0]
export const urlWidth = (url, w) => cleanUri(url ? convertResizableUrl(url) : '') + '?w=' + w

export const getUserCity = (timezone) => (timezone?.split('/')[1] ? timezone.split('/')[1] : timezone)

export const wordCountCalculate = (text) => {
  if (!text) {
    return 0
  }
  text = text.trim()
  return text.length > 0 ? text.split(/\s+/).length : 0
}

export const isPast = (timezone, time) => {
  return compareAsc(timezonedNow(timezone), new Date(time)) > 0
}

export const getTodoColor = (type) => {
  switch (type) {
    case TODO_TYPES.PREP:
      return 'success'
    case TODO_TYPES.REVIEW:
      return 'info'
    case TODO_TYPES.HOMEWORK:
      return 'secondary2'
    case TODO_TYPES.PREP_KO:
      return 'success'
    case TODO_TYPES.REVIEW_KO:
      return 'info'
    case TODO_TYPES.HOMEWORK_KO:
      return 'secondary2'
    default:
      break
  }
}

export const checkFinalWord = (word) => {
  if (typeof word !== 'string') return null
  const lastLetter = word[word.length - 1]
  const uni = lastLetter.charCodeAt(0)
  if (uni < 44032 || uni > 55203) return null

  return (uni - 44032) % 28 != 0
}

export const getAdjustedDateTimeMMT = (year, month, date, hour, minute, second) => {
  let adjustedDate = new Date()
  adjustedDate = setYear(adjustedDate, year)
  // date-fns에서 월은 0부터 시작하므로 1을 빼줍니다.
  adjustedDate = setMonth(adjustedDate, month - 1)
  adjustedDate = setDate(adjustedDate, date)
  adjustedDate = setHours(adjustedDate, hour)
  adjustedDate = setMinutes(adjustedDate, minute)
  adjustedDate = setSeconds(adjustedDate, second)

  return adjustedDate
}
export const getYearFromStr = (time) => parseInt(time?.slice(0, 4))
export const getMonthFromStr = (time) => parseInt(time?.slice(5, 7)) - 1
export const getDayFromStr = (time) => parseInt(time?.slice(8, 10))
export const getHourFromStr = (time: string) => parseInt(time?.slice(11, 13))
export const getMinuteFromStr = (time: string) => parseInt(time?.slice(14, 16))

export const t = (obj, lang) => {
  if (typeof obj == 'string') {
    return obj
  }
  const value = obj[localeIndex(lang)] === undefined ? obj[localeIndex(LOCALE_EN)] : obj[localeIndex(lang)]
  return value ? value : ''
}
export const getOgUrl = ({ lang, context }) => {
  const strippedPathName = context.pathname.includes('/[lang]')
    ? '/' + lang + context.pathname.replace('/[lang]', '')
    : context.pathname
  return `https://${RINGLE_DOMAIN}${strippedPathName}`
}

export const getLength = (str) => {
  if (typeof str !== 'string') {
    return 0
  }
  if (str.length == 0) {
    return 0
  }
  const temp = str
    .replace(/([\s]|(<\/?p>)|<br>|(&nbsp;))+/gm, ' ')
    .replace(/(<.*?>)/g, '')
    .trim()
  if (temp == '') {
    return 0
  }
  return temp.split(/\s+/).length
}

export enum MATH_METHOD {
  ROUND = 'round',
  CEIL = 'ceil',
  FLOOR = 'floor',
}

export enum TUTOR_STATUS {
  APPLICATION = 0,
  ACTIVE = 1,
  NEW_TUTOR = 2,
  DORMANT = 3,
  READY = 4,
  ONE_STRIKE = 5,
  TWO_STRIKE = 6,
  OUT_THREE_STRIKE = 7,
  OUT_TEN_STRIKE = 8,
  HOLD = 9,
  BLACKLIST = 10,
  NOT_AGREED_POLICY = 11,
  UNDER_REVIEW = 12,
}

export const getTutorStatusString = (statusNum: number) => {
  switch (statusNum) {
    case TUTOR_STATUS.APPLICATION:
      return 'APPLICATION'
    case TUTOR_STATUS.ACTIVE:
      return 'ACTIVE'
    case TUTOR_STATUS.NEW_TUTOR:
      return 'NEW_TUTOR'
    case TUTOR_STATUS.DORMANT:
      return 'DORMANT'
    case TUTOR_STATUS.READY:
      return 'READY'
    case TUTOR_STATUS.ONE_STRIKE:
      return 'ONE_STRIKE'
    case TUTOR_STATUS.TWO_STRIKE:
      return 'TWO_STRIKE'
    case TUTOR_STATUS.OUT_THREE_STRIKE:
      return 'OUT_THREE_STRIKE'
    case TUTOR_STATUS.OUT_TEN_STRIKE:
      return 'OUT_TEN_STRIKE'
    case TUTOR_STATUS.HOLD:
      return 'HOLD'
    case TUTOR_STATUS.BLACKLIST:
      return 'BLACKLIST'
    case TUTOR_STATUS.NOT_AGREED_POLICY:
      return 'NOT_AGREED_POLICY'
    case TUTOR_STATUS.UNDER_REVIEW:
      return 'UNDER_REVIEW'
    default:
      return 'NOT_DEFINED'
  }
}

export enum DEVICE_TYPE {
  IOS,
  ANDROID,
}

export const getMobileDeviceType = (): DEVICE_TYPE => {
  if (typeof window === 'undefined') return DEVICE_TYPE.IOS
  return /iPad|iP(hone|od)/.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    ? DEVICE_TYPE.IOS
    : DEVICE_TYPE.ANDROID
}

export const getTeensAppDownLinkByDevice = () => {
  const deviceType = getMobileDeviceType()

  return deviceType === DEVICE_TYPE.IOS
    ? 'https://apps.apple.com/us/app/%EB%A7%81%EA%B8%80-%ED%8B%B4%EC%A6%88-10%EB%8C%80%EB%A7%8C%EC%9D%84-%EC%9C%84%ED%95%9C-1-1-%ED%99%94%EC%83%81-%ED%8A%9C%ED%84%B0%EB%A7%81/id1570081422'
    : 'https://play.google.com/store/apps/details?id=com.ringleteens'
}

export function deepEqual(obj1: any, obj2: any): boolean {
  if (obj1 === obj2) return true

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 == null || obj2 == null) {
    return false
  }

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (!keys2.includes(key)) return false
    if (!deepEqual(obj1[key], obj2[key])) return false
  }

  return true
}
